import React, {useEffect, useState, useMemo} from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function GrowthNav ({frontmatter,fields,children}){
  let backgroundColor = frontmatter.background_color ? frontmatter.background_color : null;
  let backgroundStyle = frontmatter.background_style ? frontmatter.background_style : "cover";
  let backgroundImages = frontmatter.background_images ? frontmatter.background_images.map(e=>e.childImageSharp) : null;
  let images = []
  if(backgroundImages){
    images = images.concat(backgroundImages)
  }
  let [bg,setBg] = useState({})
  useEffect((e) => {
    if(images.length){
      setBg(images[Math.floor(Math.random()*images.length)])
    }

    if(typeof window !== "undefined"){

    }
  },[])
  if(backgroundImages)
    images = images.concat(backgroundImages)
  let style = {
    backgroundColor: backgroundColor ? backgroundColor : null,
    backgroundSize: backgroundStyle ? backgroundStyle : 'cover',
  }

  return <div id="growth-nav" className={"page-wrapper page-"+(fields.slug ? fields.slug.slice(1,-1): "")} style={style}><div id="ladybug-nav">
        <a href="/growth">back to bugs</a>
      </div>
      <div className="page-bg">
        {bg.gatsbyImageData && <GatsbyImage object-fit="cover" image={getImage(bg.gatsbyImageData)} alt="" />}
      </div>
      {children}
    </div>
  }
